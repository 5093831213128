.link{
  color: var(--color-text-gray);
  font: 400 1.6rem/150% var(--Baloo);
  cursor: pointer;
  &:hover{
    color: var(--color-brown);
  }
}
.fill-button{
  display: flex;
  padding: 1.6rem 3rem 1.6rem 2rem;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  border-radius: 3rem;
  background: var(--color-blue);
  cursor: pointer;
  img{
    width: 2rem;
    height: 2rem;
  }
  color: var(--color-white);
  font: 400 1.6rem/150% var(--Baloo);
}
.button-transparent{
  display: flex;
  padding: 1.6rem 3rem 1.6rem 2rem;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  border-radius: 3rem;
  border: .1rem solid var(--color-orange);
  background: var(--color-light-white);
  color: var(--color-orange);
  font: 400 1.6rem/150% var(--Baloo);
  cursor: pointer;
  transition: .3s all linear;
  &:hover{
    svg{
      filter: brightness(0) invert(1);
    }
    color: white;
    background: var(--color-orange, #F79F1F)!important;
  }
}
.container{
  max-width: 117rem;
  margin: 0 auto;
}
.no-scroll{
  overflow: hidden;
}

.header{
  background: var(--color-light-white);
  height: 8.6rem;
  .login-auth:hover{
    color: var(--color-blue, #2D98DA);
  }
  &-wrapper{
      max-width: 117rem;
      margin: 0 auto;
      display: flex;
      align-items: center;
    justify-content: space-between;
    padding: 1.4rem 0;
  }
  .logo img{
    height: 4.1rem;
    cursor: pointer;
  }
  &-search{
    svg{
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  &-auth{
    display: flex;
    align-items: center;
    gap: 2rem;
    svg{
      width: 1.4rem;
      height: 1.4rem;
    }
    &-btn{
      display: flex;
      align-items: center;
      gap: 2rem;
      .sign-auth{
        transition: .3s all linear;
      }
      .sign-auth:hover{
        svg{
          filter: brightness(0) invert(1);
        }
          color: white;
          background: var(--color-orange, #F79F1F);
      }
    }
  }
  &-dropdown{
    color: var(--color-text-gray);
    font: 400 1.6rem/150% var(--Baloo);
    display: flex;
    align-items: center;
    gap: 0.4rem;
    cursor: pointer;
    &:hover{
      color: var(--color-brown);
    }
    &.active{
      filter: invert(55%) sepia(19%) saturate(3523%) hue-rotate(175deg) brightness(91%) contrast(86%);
    }
    svg{
      width: 1.2rem;
      height: 1.2rem;
    }
  }
  .header-menu-mobile{
    display: none;
  }
  &-menu{
    display: flex;
    gap: 3rem;
    align-items: center;
    li{
      color: var(--color-text-gray);
      font: 400 1.6rem/150% var(--Baloo);
      display: flex;
      align-items: center;
      gap: 0.4rem;
      cursor: pointer;
      &:hover{
        color: var(--color-blue);
      }
      svg{
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }
  .dropdown-menu{
    position: absolute;
    border-radius: 2rem;
    background: var(--color-white);
    box-shadow: 0 5rem 5rem -2rem var(--blue-shadow1);
    padding: 4rem 5rem;
    display: flex;
    display: none;
    gap: 20rem;
    left: 50%;
    transform: translate(-50%);
    width: 61vw;
    max-width: 117rem;
    top: 9rem;
    z-index: 4;
    &-item{
      &:first-child ul li:nth-child(3){
        padding-right: 10rem;
      }
      h3{
        color: var(--color-text-gray);
        font: 500 1.8rem/150% var(--Baloo);
        margin-bottom: 1.6rem;
      }
      ul{
        border-top: .1rem solid var(--color-light-white1);
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex-wrap: wrap;
        max-height: 18.6rem;
        padding-top: 1.6rem;
        li{
          color: var(--color-text-gray);
          font: 400 1.6rem/150% var(--Baloo);

        }
      }
    }
  }
  .open{
    display: flex;
  }
  .input-search-main{
    color: var(--color-light-gray);
    font: 400 1.4rem/150% var(--Baloo);
    border: none;
    display: none;
    background: none;
  }
  .header-search{
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
  }
  &-burger{
    display: none;
    cursor: pointer;
  }
  &-close{
    cursor: pointer;
  }
}
@media (max-width: 666px) {
  .header{
    height: 4.6rem;
  }
  .header-burger{
    display: flex;
  }
  .header-auth-btn{
    padding-bottom: 10rem;
  }
  .header .input-search-main{
     width: 100%;
    display: flex;
  }
  .header-search {
    text-align: left;
    width: 100%;
    padding: 1.6rem;
    border-bottom: .1rem solid var(--color-light-white1);
  }
    .header{
    background: none;
  }
  .header-menu li {
    display: none;
  }
  .header .open {
    flex-direction: column;
  }
  .header .dropdown-menu {
    left: 0;
    width: 100vw;
    transform: none;
    padding: 2rem 1.6rem;
    gap: 2rem;
    top: 4.6rem;
    height: 100vh;
  }
  .header .dropdown-menu-item ul li{
    font: 400 1.4rem/150% var(--Baloo);
  }
  .header .dropdown-menu-item h3 {
    font: 500 1.6rem/150% var(--Baloo);
    margin-bottom: 1rem;
  }
  .header .dropdown-menu-item:first-child ul li:nth-child(3){
    padding: 0;
  }
  .header .dropdown-menu-item ul{
    align-content: space-between;
    padding-top: 1rem;
  }
  .header-auth{
    position: fixed;
    top: 4.6rem;
    background: white;
    width: 100vw;
    height: calc(100vh - 4.6rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    left: 0;
    border-top: .1rem solid var(--color-light-white1);
    display: none;
    z-index: 20;
    margin-bottom: 5rem;
  }
  .blog-one .blog-one-btn a p{
    font-size: 1.6rem!important;
  }
  .header .header-menu-mobile{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    li{
      color: var(--color-text-gray);
      font: 400 2rem/160% var(--Baloo);
    }
  }
  .header .logo img {
    height: 2.4rem;
    object-fit: contain!important;
    object-position: left;
  }
  .header-wrapper{
    padding: 1rem 1.6rem;
  }

  .login-auth{
    width: 40vw;
    display: flex;
    padding: 1.6rem 3rem 1.6rem 2rem;
    justify-content: center;
    align-items: center;
    gap: 0.6rem;
    border-radius: 3rem;
    border: .1rem solid var(--color-blue);
    background: var(--color-light-white);
    color: var(--color-blue);
    font: 400 1.6rem/150% var(--Baloo);
    cursor: pointer;
  }
  .sign-auth{
    width: 40vw;
  }
}

.our-achieve{
  &-wrapper{
    display: flex;
    align-items: flex-start;
    padding: 19rem 0 10rem;
    gap: 3rem;
    flex-wrap: wrap;
    justify-content: space-between;
    li:nth-child(2){
      margin-bottom: 2rem;
    }
      li{
        max-width: 37rem;
        width: 23vw;
        display: flex;
        gap: 1rem;
        flex-direction: column;
        align-items: center;
      }
    img{
      width: 3rem;
      height: 3rem;
      margin-bottom: 1rem;
    }
    h5{
      color: var(--color-text-gray);
      text-align: center;
      font: 500 1.8rem/150% var(--Baloo);
    }
    p{
      color:var(--color-light-gray);
      text-align: center;
      font: 400 1.6rem/160% var(--SegoeUI);
      span{
        text-decoration: underline;
      }
    }
  }

}
.explore{
  padding: 0 8.7rem;

  &-tabs{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &-wrapper{
    border-radius: 3rem;
    background: var(--color-light-orange);
    max-width: 175rem;

    padding: 7rem 0;
    margin: 0 auto;
  }
  h2 {
    color: var(--color-text-gray);
    text-align: center;
    font: 600 2.4rem/150% var(--Baloo);
    margin-bottom: 2.4rem;
  }
  .explore-tabs__name{
    display: flex;
    padding: 2rem;
    align-items: center;
    border-radius: 10rem;
    background:var(--color-white);
    gap: 1rem;
    justify-content: space-around;
    max-width: 117rem;
    width: 100%;
    .active{
      color:var(--color-white);
      &::after{
        content: '';
        position: absolute;
        border-radius: 3rem;
        background: var(--Primary-Orange, var(--color-orange));
        height: 9.4rem;
        width: 11rem;
        right: 50%;
        top: 50%;
        z-index: -1;
        transform: translate(50%, -50%);
      }
    }
  }
  .explore-tabs__name li {
    color: var(--color-light-gray);
    text-align: center;
    font: 500 1.4rem/150% var(--Baloo);
    text-transform: uppercase;
    max-width: 9.3rem;
    width: min-content;
    transition: .2s all linear;
    position: relative;
    z-index: 0;
    cursor: pointer;
    &:hover{
      color: var(--color-orange, #F79F1F);
    }
  }
  .explore-tabs__text{
    display: flex;
    padding: 2.8rem 0 7rem;
    align-items: center;
    gap: 1rem;
    justify-content: space-around;
    max-width: 117rem;
    width: 100%;
  }
  .explore-tabs__text li {
    color: var(--color-light-gray);
    display: flex;
    text-align: center;
    font: 500 1.4rem/150% var(--Baloo);
    text-transform: uppercase;
    transition: .2s all linear;
    position: relative;
    z-index: 0;
    gap: 1rem;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }
  .converter-item{
    display: flex;
    padding: 2rem 3rem 2rem 2rem;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-radius: 3rem;
    background: var(--color-white);
    box-shadow: 0 1rem 2.4rem 0 var(--brown-shadow);
    cursor: pointer;
    &:hover{
      box-shadow: 0 1rem 5rem 0 rgba(247, 159, 31, 0.50);
      p{
        color: var(--color-orange, #F79F1F);
      }
    }
    p{
      color: var(--color-text-gray);
      font: 600 1.4rem/150% var(--SegoeUI);
      white-space: nowrap;
    }
    &-img{
      width: 5.2rem;
      height: 5.2rem;
      min-width: 5.2rem;
      border-radius: 10rem;
      background: var(--color-light-orange);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img{
      width: 3rem;
      height: 3rem;
    }
    &:nth-child(-n+5){
      margin-bottom: 1rem;
    }
  }

  .explore-tabs .explore-tabs__text li:not(:first-child) {
    display: none;
  }
  .back-btn{
    display: none;
  }
}
@media (max-width: 666px){
  .explore .explore-tabs__text{
    display: none;
    padding: 0 1rem 0;
    height: 38.6rem;
    overflow: auto;
  }
  .explore{
    padding: 0 .6rem;
  }
  .explore-wrapper{
    padding: 3rem 0;
    position: relative;
    height: unset;
  }
  .explore .explore-tabs__name{
    padding: 1rem;
    flex-wrap: wrap;
    background: transparent;
  }
  .explore .explore-tabs__name li{
    width: 40vw;
    max-width: unset;
    color: var(--color-white);
    border-radius: 3rem;
    background: var(--color-orange);
    font: 500 1.3rem/120% var(--Baloo);
    padding: 0.8rem;
    height: 4.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .explore h2{
    margin-bottom: 3.6rem;
    font: 600 2rem/150% var(--Baloo);
  }
  .explore .explore-tabs__name .active::after{
    content: unset;
  }
  .explore .back-btn{
    display: none;
    align-items: center;
    position: absolute;
    gap: .4rem;
    font: 400 1.6rem/150% var(--Baloo);
    left: 1.6rem;
    cursor: pointer;
    padding: 1rem;
    top: 6rem;
    svg{
      width: 1.2rem;
      height: 1.2rem;
    }
  }
  .explore .converter-item{
    padding: 0.4rem 1rem 0.4rem 0.4rem;
    gap: .4rem;
    max-width: 13.9rem;
  }
  .explore .converter-item p{
    font: 600 1.1rem/150% var(--SegoeUI);
    white-space: unset;
  }
  .explore .converter-item:nth-child(-n+5){
    margin: 0;
  }
}

@media (max-width: 666px) {
  .our-achieve-wrapper {
    flex-direction: column;
    padding: 12rem 1.6rem 10rem;
    align-items: center;
  }
  .our-achieve-wrapper li{
    width: unset;
    gap: 0;
  }
  .our-achieve-wrapper li:nth-child(2){
    margin: 0;
  }
  .our-achieve-wrapper h5{
    font-size: 1.6rem;
  }
  .our-achieve-wrapper p{
    font-size: 1.4rem;
  }
}
.explore-convers {
  h2 {
    color: var(--color-text-gray);
    text-align: center;
    font: 600 2.4rem/150% var(--Baloo);
    margin-bottom: 1rem;
  }
  &-wrapper {
    max-width: 175rem;
    padding: 7rem 0 5rem;
    height: 51.2rem;
    margin: 0 auto;
    position: relative;
    & > p{
      color: var(--color-light-gray1);
      text-align: center;
      font: 400 1.6rem/150% var(--SegoeUI);
    }
    ul{
      padding: 3rem 0 0;
      max-width: 117rem;
      width: 100%;
      margin: 0 auto;
      li{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: .1rem solid var(--color-light-blue);
        padding: 2rem;
        &:last-child{
          border-bottom: unset;
        }
        p{
          color: var(--color-text-gray);
          font: 600 1.4rem/150% var(--SegoeUI);
          white-space: nowrap;
          cursor: pointer;
          &:hover{
            color: var(--color-dark-blue);
          }
        }
      }
    }
  }
  &-img{
    position: absolute;
    left: 55%;
    transform: translate(50%, -50%);
    top: 3rem;

    img{
      width: 22.4628rem;
      height: 10.1841rem;
    }
  }
}
@media (max-width: 666px) {
  .explore-convers-wrapper{
    height: unset;
    padding-top: 15.2rem;
  }
  .explore-convers-wrapper ul li p{
    border-bottom: .1rem solid var(--color-light-blue);
    padding: 0.8rem 0;
  }
  .explore-convers-wrapper ul li{
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    border: none;
  }
  .explore-convers-wrapper ul li p{
    width: 43vw;
    text-align: center;
  }
  .explore-convers-wrapper ul{
    padding: 1.2rem 1.6rem 0;
  }
  .explore-convers h2{
    font: 600 2rem/150% var(--Baloo);
    margin: 0;
    padding: 0 1.6rem;
  }
  .explore-convers-wrapper > p{
    font: 400 1.4rem/150% var(--SegoeUI);
    padding: 0 1.6rem;
  }
  .explore-convers-wrapper ul li:last-child p:nth-child(6), .explore-convers-wrapper ul li:last-child p:nth-child(5){
    border: none;
  }
  .explore-convers-img{
    top: 3rem;
    left: 50%;
    transform: translate(-50%);
  }
}
.explore-storage{
  padding: 0 8.7rem;

  &-wrapper{
    border-radius: 3rem;
    background: var(--color-light-white1);
    max-width: 175rem;
    padding: 3rem 0;
    margin: 0 auto;
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
  }
  h3{
    color: var(--color-blue);
    text-align: center;
    font: 600 2.4rem/150% var(--Baloo);
  }
  .fill-button:hover{
    background: var(--color-dark-blue);
  }
}
@media (max-width: 666px){
  .explore-storage{
    padding: 0 0.6rem;
  }
  .explore-storage h3{
    font-size: 2rem;
  }
  .explore-storage-wrapper{
    flex-direction: column;
    padding: 3rem 1rem;
  }
}
.explore-popular{
  margin-top: 7rem;
  h2{
    color: var(--color-text-gray);
    text-align: center;
    font: 600 2.4rem/150% var(--Baloo);
  }
  &-wrapper{
    display: flex;
    align-items: flex-start;
    padding: 5rem 0 7rem;
    gap: 3rem;
    flex-wrap: wrap;
    justify-content: center;
    li:nth-child(2){
      margin-bottom: 2rem;
    }
    li{
      padding: 3rem 2rem;
      max-width: 37rem;
      width: 23vw;
      display: flex;
      gap: 2rem;
      align-items: center;
      border-radius: 3rem;
      background: var(--color-white);
      box-shadow: 0 1rem 2.4rem 0 var(--brown-shadow);
      & > div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
      }
    }
    img{
      width: 3rem;
      height: 3rem;
    }
    h5{
      color: var(--color-text-gray);
      text-align: center;
      font: 500 1.8rem/150% var(--Baloo);
    }
    p{
      color:var(--color-light-gray);
      text-align: left;
      font: 400 1.6rem/160% var(--SegoeUI);
      span{
        text-decoration: underline;
      }
    }
  }
}
@media (max-width: 666px){
  .explore-popular{
   margin-top: 5rem;
  }
  .explore-popular h2{
    font-size: 2rem;
  }
  .explore-popular-wrapper li{
    padding: 1.4rem 2rem 1.4rem 1rem;
    gap: 1rem;
    width: 100%;
  }
  .explore-popular-wrapper li > div{
    gap: .6rem;
    min-width: 9rem;
  }
  .explore-popular-wrapper h5{
    font-size: 1.6rem;
  }
  .explore-popular-wrapper p{
    font-size: 1.4rem;
  }
  .explore-popular-wrapper{
     padding: 2rem .6rem 5rem;
      gap: 1rem;
  }
  .explore-popular-wrapper li:nth-child(2){
    margin: 0;
  }
}
.accordion-container {
  position: relative;
  max-width: 117rem;
  height: auto;
  margin: 0 auto;
}

.accordion-container .set {
  position: relative;
  width: 100%;
  height: auto;
}

.accordion-container .set > a {
  display: block;
  padding: 1.4rem 2rem;
  text-decoration: none;
  border-bottom: .1rem solid var(--color-beige);
  transition: all 0.2s linear;
  color: var(--color-text-gray);
  font: 500 1.8rem/150% var(--Baloo);
  cursor: pointer;
  &:hover{
    color: var(--color-orange);
  }
}

.accordion-container .set > a.active {
  border-bottom: .2rem solid var(--color-beige);
  color: var(--color-orange);
}
.accordion-container .set > a.active .plus-acc{
  transform: rotate(180deg);
}

.accordion-container .set .plus-acc, .accordion-container .set .minus-acc {
  position: absolute;
  top: 2rem;
  right: 1rem;
}

.accordion-container .set .content {
  display: none;
  padding: 3rem 2rem;
  border-radius: 0rem 0rem 1rem 1rem;
  background: #FFFAF4;
  color: var(--color-light-gray);
  font: 400 1.6rem/160% var(--SegoeUI);
}

.accordion-container .set .content p {
  font: 400 1.6rem/2.5rem var(--SegoeUI);
  margin: 0;
  color: var(--color-light-gray);
}

@media (max-width: 666px) {
  .accordion-container .set .content p {
    font: 400 1.4rem/2rem var(--SegoeUI);
  }
}

.questions{
  padding: 0 8.7rem 6rem;
  &-wrapper{
    border-radius: 3rem;
    background: var(--color-light-orange);
    max-width: 175rem;
    padding: 7rem 0;
    margin: 0 auto;
  }
  h2 {
    color: var(--color-text-gray);
    text-align: center;
    font: 600 2.4rem/150% var(--Baloo);
    margin-bottom: 4rem;
  }
}
@media (max-width: 666px) {
  .questions {
    padding: 0 .6rem 5rem;
  }
  .questions-wrapper{
    padding: 3rem 1rem;
  }
  .accordion-container .set:last-child a{
    border-bottom: none;
  }
  .accordion-container .set a{
    font: 500 1.6rem/150% var(--Baloo);
  }
  .accordion-container .set .content{
    padding: 2rem 1.4rem;
    font: 400 1.4rem/150% var(--Baloo);
  }
  .questions h2{
    font: 600 2rem/150% var(--Baloo);
    margin-bottom: 1.6rem;
  }
  .accordion-container .set > a{
    padding: 1.4rem 3rem 1.4rem 2rem;
  }
}
.text-section{
  padding: 7rem 0;
  .container{
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
  h2{
    color: var(--color-text-gray);
    text-align: center;
    font: 600 2.4rem/150% var(--Baloo);
  }
  .text-section-block{
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
  }
  p{
    color: var(--color-light-gray);
    font: 400 1.6rem/150% var(--Baloo);
  }
}
@media (max-width: 666px) {
  .text-section{
    padding: 5rem 1.6rem;
  }
  .text-section h2{
    font: 600 2rem/150% var(--Baloo);
  }
  .text-section p{
    font: 400 1.4rem/150% var(--Baloo);
  }
  .text-section .text-section-block{
    gap: 0.8rem;
  }
}

.latest-blog{
  padding: 0 8.7rem;
  .container{
    display: flex;
    gap: 3rem;
    padding: 3rem 0 0;
  }
  &-wrapper{
    border-radius: 3rem;
    background: var(--color-light-white1);
    max-width: 175rem;
    padding: 7rem 0;
    margin: 0 auto;
    & > p{
      max-width: 76.8rem;
      color: var(--color-light-gray1);
      text-align: center;
      font: 400 1.6rem/150% var(--SegoeUI);
      margin: 0 auto;
    }
  }
  h2 {
    color: var(--color-text-gray);
    text-align: center;
    font: 600 2.4rem/150% var(--Baloo);
    margin-bottom: 1rem;
  }
  .latest-blog-item{
    display: flex;
    padding: 3rem 2rem;
    flex-direction: column;
    max-width: 37rem;
    width: 23vw;
    border-radius: 3rem;
    background: var(--color-white);
    box-shadow: 0 1rem 2.4rem 0 var(--blue-shadow);
    gap: 0.4rem;
    img{
      width: 1.4rem;
      height: 1.4rem;
      object-fit: contain;
    }
    .date{
      display: flex;
      align-items: center;
      gap: .4rem;
      color: var(--color-light-gray);
      font: 400 1.4rem/150% var(--Baloo);
    }
    h4{
      color: var(--color-text-gray);
      font: 500 1.6rem/150% var(--Baloo);
      border-bottom: .1rem solid var(--color-light-blue);
      padding-bottom: 1rem;
      margin-bottom: .6rem;
    }
    p{
      color: var(--color-light-gray);
      font: 400 1.6rem/150% var(--Baloo);
    }
  }
  .latest-blog-btns{
    display: none;
  }
}
@media (max-width: 666px) {
  .latest-blog{
    padding: 0 0.6rem;
  }
    .latest-blog .latest-blog-btns{
    display: flex;
  }
  .latest-blog .latest-blog-item{
    width: unset;
    box-shadow: unset;
    min-width: 90.5vw;
    max-width: 29rem;
  }
  .latest-blog-wrapper{
    padding: 3rem 1rem 2rem;
    position: relative;
  }
  .latest-blog .container{
    padding: 2rem 0 5rem;
  }
  .latest-blog h2{
    font: 600 2rem/150% var(--Baloo);
  }
  .latest-blog .container{
    gap: 0;
  }
  .latest-blog .latest-blog-item p{
    font: 400 1.4rem/150% var(--Baloo);
  }
  .latest-blog .latest-blog-item h4{
    font: 500 1.4rem/150% var(--Baloo);
  }
  .swiper-pagination-bullet{
    width: 0.6rem;
    height: 0.6rem;
    background: var(--color-white);
    opacity: 1;
  }
  .account-plan .swiper-pagination-bullet{
    background: var(--color-light-blue);
  }
  .swiper-pagination-bullet.swiper-pagination-bullet-active{
    width: 1rem;
    height: 1rem;
    background: var(--color-blue);
  }
  .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal{
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .latest-blog-swiper-button-prev, .latest-blog-swiper-button-next{
    border-radius: 1rem;
    background: var(--color-white);
    display: flex;
    width: 4rem;
    height: 4rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
    cursor: pointer;
  }
  .latest-blog-btns{
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 0;
    z-index: 10000;
  }
}

.footer{
  margin: 0 8.7rem;
  border-top: .1rem solid var(--color-light-blue);
  .container{
    padding: 7rem 0 5rem;
    display: flex;
    justify-content: space-between;
  }
  &-menu{
    display: flex;
    gap: 10rem;
    li{
      display: flex;
      flex-direction: column;
      gap: 1.4rem;
      p{
        color: var(--color-text-gray);
        font: 600 1.6rem/150% var(--Baloo);
      }
      a{
        color: var(--color-light-gray);
        font: 500 1.6rem/150% var(--Baloo);
        &:hover{
          color: var(--color-blue);
        }
      }
    }
  }
  .logo img{
    height: 4.1rem;
    cursor: pointer;
  }
  .info-terms{
    border-top: .1rem solid var(--color-light-blue);
    &-link{
      display: flex;
      gap: 2rem;
    }
    &-wrapper{
      padding: 2rem 0;
      p{
        color: var(--color-light-gray);
        font: 400 1.6em/150% var(--Baloo);
      }
      a{
        color: var(--color-text-gray);
        font: 400 1.6em/150% var(--Baloo);
        &:hover{
          color: var(--color-blue);
        }
      }
    }
  }
}
@media (max-width: 666px) {
  .footer {
    margin: 0 1.6rem;
  }
  .footer-menu{
    flex-wrap: wrap;
    gap: 1.4rem;
  }
  .footer .container{
    flex-direction: column;
    padding: 2rem 0;
    gap: 2rem;
  }
  .footer .logo img{
    object-fit: contain;
    height: 3rem;
    object-position: left;
  }
  .footer-menu li{
    width: 42.8vw;
  }
}
.breadcrumbs{
  font: 500 1.2rem/150% var(--Baloo);
  display: flex;
  gap:.4rem;
  max-width: 117rem;
  margin: 1rem auto 0;
  p{
    color: var(--color-blue);
  }
  span{
    color: var(--color-light-gray);

  }
  a{
    color: var(--color-light-gray);
  }
}
html{
  background: var(--color-light-white);
}
.blog{
  padding-bottom: 7rem;
  h1{
    color: var(--color-text-gray);
    text-align: center;
    font: 700 4rem/150% var(--Baloo);
    padding: 1.1rem 0 3rem;
  }
  &-wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    max-width: 117rem;
    margin: 0 auto;
  }
  .blog-item{
    display: flex;
    max-width: 37rem;
    width: 23vw;
    padding: 2rem 2rem 3rem 2rem;
    flex-direction: column;
    border-radius: 3rem;
    background: var(--color-white);
    box-shadow: 0 1rem 2.4rem 0 rgba(45, 152, 218, 0.14);
    a{
      font: 600 1.4rem/150% var(--SegoeUI);
      color: var(--color-blue);
      text-decoration-line: underline;
      padding-top: 2rem;
    }
    p{
      color: var(--color-light-gray);
      font: 400 1.6rem/160% var(--SegoeUI);
    }
    h3{
      color: var(--color-text-gray);
      font: 500 1.8rem/160% var(--Baloo);
      padding-top: 2rem;
    }
  }
  .format-to-format-img{
    border-radius: 1rem;
    background: var(--color-light-white1);
    width: 100%;
    padding: 3.4rem 0;
    gap: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10rem;
    img:nth-child(n){
      width: 2.5rem;
      height: 3rem;

    }
    img:nth-child(2){
      width: 2rem;
      height: 2rem;
    }
    &-bg{
      width: 6.6rem;
      height: 6.6rem;
      border-radius: 10rem;
      background: var(--color-white);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
@media (max-width: 666px) {
  .blog-wrapper{
    flex-direction: column;
    gap: 1rem;
  }
  .blog .blog-item{
    width: unset;
  }
  .blog .blog-item p{
    font-size: 1.4rem;
  }
  .blog .blog-item h3{
    font-size: 1.6rem;
  }
  .blog{
    padding: 0 1.6rem 7rem;
  }
  .blog h1{
    font-size: 2rem;
    padding: 1.6rem 0;
  }
  .breadcrumbs{
    justify-content: center;
  }
}
.faq-page{
  h2{
    color: var(--color-text-gray);
    font: 700 4rem/160% var(--Baloo);
    margin: 1.1rem auto 3rem;
    display: flex;
    justify-content: center;
  }
  .questions{
    margin-bottom: 7rem;
  }
}
@media (max-width: 666px) {
  .faq-page h2{
    font-size: 2rem;
    margin: 1.6rem auto;
  }
  .faq-page .questions-wrapper{
    padding: 2rem 1rem;
  }
}
.about-page{
  .about{
    margin-bottom: 10rem;
  }
  h2{
    color: var(--color-text-gray);
    font: 700 4rem/160% var(--Baloo);
    margin: 1.1rem auto 1rem;
    display: flex;
    justify-content: center;
  }
  & > p{
    color: var(--color-text-gray);
    font: 500 1.8rem/150% var(--Baloo);
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
  }
  .about-wrapper{
    max-width: 117rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }
  .about-item{
    width: 100%;
    display: flex;
    padding: 6rem 3rem;
    gap: 3rem;
    border-radius: 3rem;
    background: var(--color-white);
    box-shadow: 0 1rem 2.4rem 0 rgba(45, 152, 218, 0.14);
    &-text{
      display: flex;
      flex-direction: column;
      gap: 1rem;
      ul{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        li{
          color: var(--color-text-gray);
          font: 600 1.4rem/160% var(--SegoeUI);
          padding-left: 1.4rem;
          position: relative;
          &:after{
            content: '';
            position: absolute;
            width: 0.6rem;
            height: 0.6rem;
            left: 0;
            top: .7rem;
            border-radius: 1rem;
            background: var(--color-blue);
          }
        }
      }
    }
    h3{
      color: var(--color-text-gray);
      font: 600 2.4rem/150% var(--Baloo);
    }
    p{
      color: var(--color-light-gray);
      font: 400 1.6rem/160% var(--SegoeUI);
    }
    img{
      width: 26.2rem;
      height: 26.2rem;
      object-fit: cover;
      border-radius: 3rem;
    }
    &:nth-child(2n){
      flex-direction: row-reverse;
    }
  }
}
@media (max-width: 666px) {
  .about-page > p{
    align-items: center;
    text-align: center;
    padding: 0 1rem;
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }
  .about-page h2{
    font-size: 2rem;
  }
  .about-page .about-item{
    flex-direction: column;
    padding: 3rem 1.6rem 1.6rem;
  }
  .about-page .about-item:nth-child(2n){
    flex-direction: column;
  }
  .about-page .about-wrapper{
    padding: 0 1.6rem;
    gap: 1rem;
  }
  .about-page .about-item img{
    width: 100%;
    max-height: 10rem;
  }
  .about-page .about-item p{
    font-size: 1.4rem;
  }
  .about-page .about-item h3{
    font-size: 2rem;
  }
  .about-page .about{
    margin-bottom: 7rem;
  }
  .about-page .about-item-text ul li{
    font-size: 1.3rem;
  }
}
.blog-one{
  &-wrapper{
    max-width: 97rem;
    margin: 0 auto;
    padding: 4.1rem 0 7rem;
  }
  .blog-one-first-img{
    border-radius: 1rem;
    width: 100%;
    padding: 1.7rem 0;
    gap: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-light-white1);
    img:nth-child(n){
      width: 2.5rem;
      height: 3rem;

    }
    img:nth-child(2){
      width: 2rem;
      height: 2rem;
    }
    .format-to-format-img-bg{
      width: 6.6rem;
      height: 6.6rem;
      border-radius: 10rem;
      background: var(--color-white);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-text{
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  h1{
    color: var(--color-text-gray);
    font: 700 4rem/150% var(--Baloo);
    padding: 1rem 0;
  }
  h2{
    color: var(--color-text-gray);
    font: 500 1.8rem/150% var(--Baloo);
    margin-top: 2rem;
  }
  h3{
    color: var(--color-text-gray);
    font: 600 1.4rem/150% var(--SegoeUI);
  }
  p{
    color: var(--color-light-gray);
    font: 400 1.6rem/160% var(--SegoeUI);
    span{
      color: var(--color-blue);
      font-weight: 600;
    }
  }
  .blog-one-text-one-img{
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    margin: 2rem 0;
  }
  .blog-one-text-two-img{
    display: flex;
    gap: 2rem;
    img{
      width: 49%;
      height: 100%;
      object-fit: contain;
    }
  }
  .video-youtube{
    background: var(--color-bone);
    padding: 3rem;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  .mark-text{
    border-radius: 1rem;
    background: var(--color-light-orange);
    color: var(--color-text-gray);
    font: 600 1.4rem/150% var(--SegoeUI);
    padding: 2rem;
    margin: 2rem 0;
  }
  ul{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    li{
      font: 400 1.6rem/160% var(--SegoeUI);
      padding-left: 1.4rem;
      position: relative;
      color: var(--color-light-gray);
      &:after{
        content: '';
        position: absolute;
        width: 0.6rem;
        height: 0.6rem;
        left: 0;
        top: .9rem;
        border-radius: 1rem;
        background: var(--color-blue);
      }
    }
  }
  ol{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-left: 2rem;
    li{
      font: 400 1.6rem/160% var(--SegoeUI);
      padding-left: 1.4rem;
      position: relative;
      color: var(--color-light-gray);
    }
  }
  .list-blue span{
    color: var(--color-blue);
    font-weight: 600;
  }
  .list-black span{
    color: var(--color-text-gray);
    font-weight: 600;
  }
  .blog-one-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3rem;
    a{
      display: inline-flex;
      padding: 1rem 2rem;
      justify-content: center;
      align-items: center;
      gap: 0.4rem;
      border-radius: 1rem;
      background: var(--color-white);
      span{
        font: 500 1.4rem/150% var(--Baloo);
        color: var(--color-light-gray);
      }
      p{
        color: var(--color-text-gray);
        font: 500 1.8rem/150% var(--Baloo);
      }
    }
  }
}
@media (max-width: 666px) {
  .blog-one-wrapper{
    padding: 1.3rem 1.6rem 7rem;
  }
  .blog-one p{
    font-size: 1.4rem ;
  }
  .blog-one h1{
    font-size: 2rem ;
  }
  .blog-one h2{
    font-size: 1.6rem ;
  }
  .blog-one h3{
    font-size: 1.3rem ;
  }
  .blog-one ul li, .blog-one ol li{
    font-size: 1.4rem ;
  }
  .blog-one .blog-one-btn{
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }
  .blog-one-text-two-img{
    flex-direction: column;
  }
  .blog-one .blog-one-text-two-img img{
    width: 100%;
  }
  .blog-one .blog-one-text-one-img{
    margin: 1rem 0;
  }
  .blog-one .mark-text{
    padding: 2rem 1rem;
    margin: 1rem 0;
    font-size: 1.3rem;
  }

}
.page-contact{
  .contact{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10.9rem;
    h1{
      color: var(--color-text-gray);
      font: 700 4rem/150% var(--Baloo);
      margin: 0 auto;
      padding: 1.1rem 0 3rem;
    }
    &-item{
      display: flex;
      flex-direction: column;
      gap: .6rem;
      padding: 6rem 2rem;
      border-radius: 3rem;
      background: var(--color-white);
      box-shadow: 0 1rem 2.4rem 0 rgba(45, 152, 218, 0.14);
      width: 49%;
      h3{
        color: var(--color-text-gray);
        font: 500 1.8rem/150% var(--Baloo);
      }
      h2{
        color: var(--color-text-gray);
        font: 600 2.4rem/150% var(--Baloo);
      }
      p{
        color: var(--color-light-gray);
        font: 400 1.6rem/160% var(--SegoeUI);
        span{
          text-decoration-line: underline;
        }
      }
      .button-transparent{
        width: max-content;
        margin-top: 2.4rem;
        background: var(--color-white);
      }
    }
    &-wrapper{
      display: flex;
      gap: 2rem;
      max-width: 117rem;
    }
  }
}
.contact-form{
  display: flex;
  min-width: 63rem;
  padding: 6rem 2rem;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  border-radius: 3rem;
  background: var(--color-white);
  box-shadow: 0 1rem 2.4rem 0 rgba(45, 152, 218, 0.14);
  h2{
    color: var(--color-text-gray);
    text-align: center;
    font: 600 2.4rem/150% var(--Baloo);
    margin-bottom: 3rem;
  }
  .contact-form-input{
    display: flex;
    flex-direction: column;
    position: relative;
    &-error{
      position: absolute;
      bottom: -2.2rem;
      color: var(--color-error-red2);
      font: 600 1.2rem/150% var(--Baloo);
      right: 0;
    }
  }
  input{
    display: flex;
    padding: 1.4rem;
    align-items: center;
    gap: 1rem;
    border-radius: 1rem;
    border: .1rem solid var(--color-text-gray);
    background: var(--color-white);
    min-width: 35rem;
    width: 100%;
    font: 400 1.4rem/150% var(--Baloo);
    height: 5rem;
    &.error{
      border: .1rem solid var(--color-error-red2);
    }
    &.active{
      padding-bottom: 0;
      padding-top: 2rem;
    }
  }
  textarea{
    display: flex;
    padding: 1.4rem;
    align-items: center;
    gap: 1rem;
    border-radius: 1rem;
    border: .1rem solid var(--color-text-gray);
    background: var(--color-white);
    min-width: 35rem;
    width: 100%;
    font: 400 1.4rem/150% var(--Baloo);
    height: 10rem;
    resize: none;
    &.error{
      border: .1rem solid var(--color-error-red2);
    }
    &.active{
      padding-bottom: 0;
      padding-top: 2rem;
    }
  }
  .label{
    pointer-events: none;
    position: absolute;
    cursor: text;
    z-index: 2;
    top: 2.6rem;
    transform: translateY(-50%);
    left: 2rem;
    font: 400 1.4rem/150% var(--Baloo);
    transition: all .3s ease;
    color: var(--color-light-gray);
    &.active{
      top: 1rem;
      left: 1.6rem;
      transform: none;
      color: var(--color-text-gray);
      font: 600 1rem/150% var(--Baloo);
    }
  }
  form{
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;
    justify-content: center;
  }
  .button-transparent{
    border: .1rem solid var(--color-orange);
    background: var(--color-orange);
    color: var(--color-white);
    padding: 1.6rem 5rem;
    &:hover{
      transition: .1s all linear;
      background: var(--color-blue)!important;
      border: .1rem solid  var(--color-blue);
    }
    &:disabled{
      background:#FDE2BC;
      border: .1rem solid transparent;
    }

  }
}
@media (max-width: 666px) {
  .contact-form{
    min-width: 29rem;
    padding: 3rem 1rem;
  }
  .contact-form input, .contact-form textarea{
    min-width: 25rem;
  }
  .contact-form form{
    gap: 2rem;
  }
  .contact-form .contact-form-input-error{
    font-size: .9rem;
    bottom: -1.5rem;
  }
  .contact-form h2{
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  .contact-form .button-transparent{
    padding: 0.6rem 5rem;
  }
  .contact-form .button-transparent:hover{
    //border:none;
  }
  .page-contact .contact h1{
    font-size: 2rem;
    text-align: center;
    padding: 1.6rem 0 2rem;
  }
  .page-contact .contact-wrapper{
    flex-direction: column;
    padding: 0 1.6rem;
  }
  .page-contact .contact-item{
    width: unset;
    padding: 3rem 2rem;
  }
  .page-contact .contact-item .button-transparent{
    margin-top: 1.4rem;
  }
  .page-contact .contact-item h3{
    font-size: 1.6rem;
  }
  .page-contact .contact-item h2{
    font-size: 2.4rem;
  }
  .page-contact .contact-item p{
    font-size: 1.4rem;
  }
}
.convertor{
  border-radius: 3rem;
  background: var(--color-light-white1);
  margin: 0 8.7rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 47.9rem;
  h1{
    color: var(--color-blue);
    font: 700 4rem/150% var(--Baloo);
    margin-top: 4rem;
  }
  & > p{
    color: var(--color-light-gray1);
    font: 400 1.6rem/150% var(--SegoeUI);
  }
  &-wrapper{
    position: relative;
    top: 4.1rem;
    width: 83rem;
    border-radius: 2rem;
    background: var(--color-white);
    box-shadow: 0 5rem 5rem -2rem rgba(30, 94, 134, 0.20);
  }
  &-body{
  }
  &-footer{
    border-radius: 0 0 2rem 2rem;
    background: var(--color-light-white1);
    position: relative;
    display: none;
    justify-content: center;
    padding: 2rem 0;
    &.show{
      display: flex;
    }
    .select-files{
      display: flex;
      padding: 1.4rem 2.4rem 1.4rem 3rem;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      border-radius: 1.6rem;
      background: var(--color-blue);
      font: 600 1.4rem/150% var(--SegoeUI);
      color: var(--color-white);
      cursor: pointer;
      & img:first-child{
        width: 2.2rem;
        height: 2.2rem;
      }
      & img:nth-child(2){
        width: 1.2rem;
        height: 1.2rem;
      }
      &:hover{
        background: var(--color-dark-blue);
      }
    }
    .select-files-dropdown{
      display: none;
      flex-direction: column;
      background: var(--color-light-white);
      border-radius: 1rem;
      max-width: 18.7rem;
      width: 100%;
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      top: 7.5rem;
      box-shadow: 0 5rem 5rem -2rem rgba(30, 94, 134, 0.20);
      &.open{
        display: flex;
      }
      &-item{
        display: flex;
        padding: 1.4rem;
        align-items: center;
        gap: 1rem;
        font: 600 1.2rem/150% var(--SegoeUI);
        color: var(--color-text-gray);
        cursor: pointer;
        .input-file-trigger{
          font: 600 1.2rem/150% var(--SegoeUI);
          color: var(--color-text-gray);
          cursor: pointer;
        }
        &:hover{
          color: var(--color-blue);
          background: var(--color-light-white1);
          .input-file-trigger{
            color: var(--color-blue);
          }
          img{
            filter: invert(55%) sepia(19%) saturate(3523%) hue-rotate(175deg) brightness(91%) contrast(86%);
          }
        }
        &:first-child{
          border-radius: 1.6rem 1.6rem 0 0;
        }
        &:last-child{
          border-radius: 0 0 1.6rem 1.6rem;
        }
        img{
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
  .progress-convertion{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: none;
    &-wrapper{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      padding: 2.6rem 0;
    }
    p{
      color: var(--color-text-gray);
      text-align: center;
      font: 600 1.6rem/150% var(--SegoeUI);
    }
    & > p{
      color: var(--color-light-gray);
      text-align: center;
      font: 600 1.4rem/150% var(--SegoeUI);
      margin-bottom: 1.8rem;
    }
    .progress-convertion-bar {
      width: 20rem;
      background-color:var(--color-light-white1);
      border-radius: 1rem;
    }

    .progress-convertion-bar-side {
      width: 1%;
      height: 1.1rem;
      background-color: var(--color-blue);
      border-radius: 1rem;
    }
    &.show{
      display: flex;
    }
  }
  &-footer2{
    border-radius: 0 0 2rem 2rem;
    background: var(--color-light-white1);
    position: relative;
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    & > p{
      color: var(--color-light-blue2);
      font: 400 1.3rem/150% var(--SegoeUI);
    }
    &.show{
      display: flex;
    }
    label{
      display: flex;
      padding: 1.6rem 1rem 1.6rem 2rem;
      justify-content: center;
      align-items: center;
      gap: 0.6rem;
      border-radius: 1rem;
      border: .1rem solid var(--color-blue);
      background: var(--color-white);
      font: 400 1.6rem/150% var(--Baloo);
      color: var(--color-blue);
      cursor: pointer;
      img{
        width: 1.3rem;
        height: 1.3rem;
      }
    }
    .select-files-load{
      display: flex;
      align-items: center;
      gap: 2.4rem;
      &-item{
        cursor: pointer;
        img{
          width: 2rem;
          height: 2rem;
          filter: invert(61%) sepia(18%) saturate(6279%) hue-rotate(175deg) brightness(89%) contrast(90%);
        }
      }
    }
    .btn-convert{
      display: flex;
      height: 5rem;
      padding: 1.6rem 5rem;
      justify-content: center;
      align-items: center;
      gap: 0.6rem;
      color: var(--color-white);
      font: 400 1.6rem/150% var(--Baloo);
      border-radius: 1rem;
      background: var(--color-blue);
      cursor: pointer;
    }

  }
  .drop-area {
    height: 11.2rem;
    border-radius: 2rem;
    border: .2rem dashed var(--color-light-blue3);
    background: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1.4rem 1.4rem 4rem;

    h3{
      color: var(--color-light-blue4);
      text-align: center;
      font: 400 1.8rem/150% var(--Baloo);
    }
    p{
      color: var(--color-light-gray1);
      font: 400 1.4rem/150% var(--SegoeUI);
      .dropFiles-link{
        color: var(--color-blue);
        font-weight: 600;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }
  
  .progress-bar{
    display: flex;
    justify-content: space-between;
    max-width: 56.4rem;
    margin: 5rem auto;
    position: relative;
    &-text{
      position: absolute;
      top: 4.8rem;
      white-space: nowrap;
      font-size: 1.6rem;
    }
    .progress-line{
      background: var(--color-light-white1);
      height: 0.4rem;
      position: absolute;
      width: 100%;
      max-width: 56.4rem;
      top: 1.1rem;
      z-index: 1;
    }
    & > a{
      img{
        width: 1.3rem;
        height: 1.3rem;
      }
      display: flex;
      align-items: center;
      gap:.4rem;
      color: var(--color-light-gray1);
      font: 400 1.4rem/150% var(--SegoeUI);
      text-decoration-line: underline;
      position: absolute;
      top: 8rem;
      left: -8.7rem;
      &:hover{
        filter: invert(55%) sepia(19%) saturate(3523%) hue-rotate(175deg) brightness(91%) contrast(86%);
      }
    }
  }
  .active::after,
  .active::before {
    content: '';
    position: absolute;
    border: .5rem solid rgba(45, 152, 218, 0.5);
    left: -1rem;
    opacity: 0;
    right: -1rem;
    top: -1rem;
    bottom: -1rem;
    border-radius: 50%;
    animation: active 2.5s linear infinite;
  }

  .active::after {
    animation-delay: 1.25s;
  }

  @keyframes active {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
  }
  .circle-1, .circle-2, .circle-3{
    position: relative;
    border-radius: 5rem;
    background: var(--color-light-white1);
    color: var(--color-light-blue4);
    display: flex;
    width: 2.8rem;
    height: 2.8rem;
    justify-content: center;
    align-items: center;
    font: 600 1.4rem/150% var(--Baloo);
    z-index: 2;

    &.active{
      color: var(--color-white);
      background:  var(--color-blue);
      .progress-bar-text{
        color: var(--color-text-gray);
      }
    }
    &.complete{
      background:  var(--color-blue);
      &:after{
        content: '';
        background: url("../img/progress-complete.png");
        position: absolute;
        width: 1.5rem;
        height: 1.5rem;
        background-position: center;
        background-color: var(--color-blue);
        background-size: 1rem;
        background-repeat: no-repeat;
      }
      .progress-bar-text{
        color: var(--color-light-gray);
      }
    }
  }
  .file-list{
    display: flex;
    flex-direction: column;
    margin-top: 10rem;
    border-top: .1rem solid var(--color-light-white1);
    &-item{
      padding: 1rem 3rem;
      display: grid;
      align-items: center;
      border-bottom: .1rem solid var(--color-light-white1);
      &.good{
        grid-template-columns: 3fr 3fr 2fr 2fr 1fr;
      }
      &.error{
        grid-template-columns: 3fr 5fr 2fr 1fr;
      }
      &.conversion{
        grid-template-columns: 4fr 4fr 4fr;
      }
      &.completed{
        grid-template-columns: 3fr 3fr 4fr 2fr;
      }
      &-name{
        color: var(--color-text-gray);
        font: 400 1.4rem/150% var(--Baloo);
        max-width: 20rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &-select{
        color: var(--color-text-gray);
        font: 400 1.4rem/150% var(--Baloo);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
      }
      &-error{
        color: var(--color-error-red);
        font: 600 1.2rem/150% var(--SegoeUI);
        text-align: center;
      }
      &-label{
        display: flex;
        padding: 0.4rem 1rem;
        justify-content: center;
        align-items: center;
        border-radius: 1rem;
        font: 500 1.2rem/150% var(--Baloo);
        text-transform: uppercase;
        width: max-content;
        height: max-content;
        background: var(--color-white);
        margin: 0 auto;
        &.prepared{
          color: var(--color-blue);
          border: .1rem solid var(--color-blue);
          width: 7.6rem;
        }
        &.error{
          color: var(--color-error-red);
          border: .1rem solid var(--color-error-red);
          width: 7.6rem;  
        }
        &.conversion{
          color: var(--color-turquoise);
          border: .1rem solid var(--color-turquoise);
        }
        &.completed{
          color: var(--color-orange);
          border: .1rem solid var(--color-orange);
        }
      }
      &-size{
        color: var(--color-light-gray);
        font: 400 1.4rem/150% var(--Baloo);
        text-align: center;
      }
      &-delete{
        display: flex;
        justify-content: flex-end;
        img{
          width: 2rem;
          height: 2rem;
          cursor: pointer;
        }
      }
      &-cancel{
        display: flex;
        padding: 1.6rem 5rem;
        justify-content: center;
        align-items: center;
        border-radius: 1rem;
        border: .1rem solid var(--color-light-gray1);
        background: var(--color-white);
        color: var(--color-light-gray1);
        font: 400 1.6rem/150% var(--Baloo);
        width: max-content;
        margin: 0 0 0 auto;
        cursor: pointer;
      }
      &-download{
        display: flex;
        padding: 1.6rem 5rem;
        justify-content: center;
        align-items: center;
        border-radius: 1rem;
        background: var(--color-blue);
        color: var(--color-white);
        font: 400 1.6rem/150% var(--Baloo);
        width: max-content;
        margin: 0 0 0 auto;
        cursor: pointer;
      }
    }
  }
}
.convertor{
  .drop-area-mobile{
    display: none;
    color: var(--color-light-gray1);
    font: 400 1.4rem/150% var(--SegoeUI);
    text-align: center;
    justify-content: center;
    padding-top: 2rem;
  }
  .select2-container--default .select2-selection--single{
    border-radius: 1rem;
    border: .1rem solid var(--color-light-gray);
  }
  .select2-container .select2-selection--single .select2-selection__rendered{
    padding-left: 1rem;
    padding-right: 1rem;
    color: var(--color-text-gray);
    font: 400 1.4rem/150% var(--Baloo);
    height: 100%;
    display: flex;
    align-items: center;
  }
  .select2-container--default.select2-container--open.select2-container--below .select2-selection--single{
    border-radius: 1rem;
  }
  .convert-all{
    display: none;
    padding: 1.6rem 0;
    justify-content: center;
    &.show{
      display: flex!important;
    }
    .select2-container {
      width: 13rem !important;
    }
    .select2-container--default .select2-selection--single{
      border: none!important;
    }
    .select2-container .select2-selection--single .select2-selection__rendered{
      font: 400 1.4rem/150% var(--SegoeUI);
      color: var(--color-light-gray1);
    }
  }
}
.select2-dropdown{
  width: 38.7rem!important;
  border-radius: 2rem!important;
  border: .1rem solid var(--color-light-white1)!important;
  background: var(--color-white);
  box-shadow: 0 5rem 5rem -2rem rgba(30, 94, 134, 0.20);
  padding: 2rem 0 2rem 2rem;
}
@media (max-width: 666px) {
  .convertor .progress-bar-text{
    font-size: 1.4rem;
  }
  .convertor .drop-area-mobile{
    display: flex;
  }
  .convertor-wrapper{
    width: 95vw;
  }
  .convertor .progress-bar{
    width: 75vw;
  }
  .convertor .drop-area{
    display: none!important;
  }
  .convertor .progress-bar > a{
    left: 50%;
    transform: translate(-50%);
    width: max-content;
  }
  .convertor{
    margin: 0;
    border-radius: 0;
    height: 38.4rem;
  }
  .convertor .progress-bar{
    margin: 2rem auto;
  }
  .convertor-footer{
    padding: 1rem;
  }
  .convertor-footer .select-files{
    width: 100%;
    max-width: 28.8rem;
  }
  .convertor-footer .select-files img:nth-child(2){
    margin-left: auto;
  }
  .convertor-footer .select-files-dropdown{
    top: 6.5rem;
    max-width: 28.8rem;
  }
  .convertor h1{
    margin-top: 2rem;
    font-size: 3rem;
  }
  .convertor > p{
    font-size: 1.4rem;
  }
  .convertor-footer2 > p{
    display: none;
  }
  .convertor-footer2{
    padding: 1.4rem 1rem 6.8rem;
    position: sticky;
    bottom: 0;
  }
  .convertor-footer2 .btn-convert{
    position: absolute;
    bottom: 0;
    left: 0;
    width: -webkit-fill-available;
    border-radius: 0 0 1rem 1rem;
    height: 5.4rem;
  }
  .convertor-footer2 label{
    padding: .6rem 2rem .6rem 3rem;
  }
  .convertor-footer2 .select-files-load{
    gap: 1.4rem;
  }
  .convertor .convert-all{
    padding: .8rem 0;
  }
  .convertor .file-list-item{
    padding: 4.6rem 1rem 1rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .convertor .file-list-item.conversion{
    flex-direction: row;
  }
  .convertor .file-list-item-name{
    position: absolute;
    top: 1rem;
    left: 0;
    padding-left: 1rem;
  }
  .convertor .file-list-item-delete{
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
  .convertor .file-list-item-label{
    margin: 0;
  }
  .format-select-wrapp .select2-container--open .select2-dropdown{
    left: -22rem;
  }
  .format-select-wrapp1 .select2-container--open .select2-dropdown{
    left: -8.5rem;
  }
  .convertor .progress-convertion .progress-convertion-bar{
    width: 28vw;
  }
  .convertor .progress-convertion p{
    font-size: 1.2rem;
  }
  .convertor .progress-convertion > p{
    font-size: 1.1rem;
  }
  .select2-dropdown{
    width: 29.8rem!important;
  }
  .convertor .progress-convertion-wrapper{
    padding: .8rem 0;
    gap: .6rem;
  }
  .convertor .file-list-item-cancel{
    padding: .6rem 3rem;
  }
}

.select2-container--default .select2-results__group{
  color: var(--color-light-gray);
  font: 400 1.4rem/150% var(--Baloo);
  max-width: 10rem;
  width: 100%;
  cursor: pointer;
}
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single{
  border-radius: 1rem!important;
}
.select2-container--default .select2-results__option .select2-results__option{
  display: flex;
  width: 7rem;
  padding: 0.6rem;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  background: var(--color-light-white1);
  color: var(--color-text-gray);
  font: 400 1.4rem/150% var(--Baloo);
  max-height: 3.4rem;
}
.select2-container--default .select2-results__option[role=group]{
  display: flex;
  gap: .6rem;
}
.select2-results__option .select2-results__options.select2-results__options--nested{
  display: flex;
  gap: .6rem;
  flex-wrap: wrap;
}
.select2-container--default .select2-results>.select2-results__options{
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}
.select2-container{
  width: 7rem!important;
}
.select2-container--default .select2-search--dropdown .select2-search__field{
  border: none;
  padding: .4rem .4rem .4rem 3rem;
  color: var(--color-light-gray);
  font: 400 1.4rem/150% var(--Baloo);
}
.select2-search--dropdown{
  margin-bottom: 2rem;
  border-bottom: .1rem solid var(--color-light-white1);
  &:after{
    content: '';
    position: absolute;
    width: 1.4rem;
    height: 1.4rem;
    background-image: url("../img/search.svg");
    background-size: cover;
    top: 3.4rem;
    left: 3rem;
  }
}
.select2-container--default .select2-results__option[role=group] ul{
  margin-right: 1rem;
}
.select2-container--default .select2-results__option[role=group] ul::-webkit-scrollbar {
  width: .6rem;
}
.select2-container--default .select2-results__option[role=group] ul::-webkit-scrollbar-track {
  background: var(--color-track-scroll);
}
.select2-container--default .select2-results__option[role=group] ul::-webkit-scrollbar-thumb {
  background-color: var(--color-blue);    /* color of the scroll thumb */
  border-radius: 5rem;       /* roundness of the scroll thumb */
  border: .1rem solid var(--color-blue);  /* creates padding around scroll thumb */
}
.select2-container--default .select2-results__option[aria-selected=true]:first-child{
  display: none;
}
.select2-results__option .select2-results__options.select2-results__options--nested:not(:first-child){
  display: none;
}
.select2-container--default .select2-results__option[role=group]:nth-child(2) ul{
  display: flex;
}
.select2-container--default .select2-results__option[role=group] ul {
  position: absolute;
  left: 12.6rem;
  top: 8rem;
  overflow-y: auto;
  height: 20rem;
  align-content: flex-start;
}
.select2-container--default .select2-selection--single .select2-selection__arrow{
  display: none;
}
.select2-container:after{
  position: absolute;
  content: "";
  right: 1rem;
  top: calc(50% - 0.5rem);
  width: 0.8rem;
  height: .8rem;
  background-image: url("../img/select-down.svg");
  background-size: cover;
  pointer-events: none;
}
.select2-results{
  height: 21rem;
}
.hide{
  display: none!important;
}
.show {
  display: flex !important;
}
.account-plan{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    color: var(--color-text-gray);
    font: 500 4rem/150% var(--Baloo);
    margin: 5.7rem 0 2rem;
  }
    .error-massage{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 2rem;
      gap: 1rem;
      border-radius: 3rem;
      background: var(--color-bg);
      max-width: 117rem;
      width: 100%;
      text-align: center;
      h3{
        color: var(--color-text-gray);
        font: 500 1.4rem/150% var(--Baloo);
      }
      p{
        color: var(--color-light-gray);
        font: 500 1.4rem/160% var(--SegoeUI);
      }

    }
  &-wrapper{
    max-width: 117rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
    p{
      font: 400 1.4rem/150% var(--SegoeUI);
      color:var(--color-light-gray);
    }
  }
  &-tab{
    border-radius: 1rem;
    border: .1rem solid var(--color-light-blue);
    background: var(--color-white);
    display: inline-flex;
    padding: 0.2rem;
    justify-content: center;
    align-items: center;
    li{
      display: flex;
      padding: 1rem 2rem;
      justify-content: center;
      align-items: center;
      border-radius: 0.8rem;
      font: 400 1.6rem/150% var(--Baloo);
      color: var(--color-light-blue5);
      cursor: pointer;
      &.active{
        color: var(--color-light-white);
        background:  var(--color-blue);
      }

    }
  }
  &-hidden{
    margin-top: 1rem;
    & > li{
      & > ul{
        display: flex;
        gap: 3rem;
        & > li{
          position: relative;
          display: flex;
          width: 24.3vw;
          max-width: 37rem;
          padding: 3rem 2rem;
          flex-direction: column;
          align-items: center;
          border-radius: 3rem;
          background: var(--color-white);
          box-shadow: 0 1rem 2.4rem 0 rgba(45, 152, 218, 0.14);
        }
      }
    }
  }
  &-name{
    color: var(--color-text-gray);
    font: 500 1.8rem/150% var(--Baloo);
    margin-bottom: 3rem;
  }
  &-price{
    position: relative;
    p{
      color: var(--color-text-gray);
      font: 400 7rem/130% var(--Baloo);
    }
    span{
      font: 500 2rem/150% var(--Baloo);
      vertical-align: top;
    }
    .sales-price{
      position: absolute;
      top: -2rem;
      left: calc(50% - 2.7rem);
      color: var(--color-orange);
      font: 400 1.6rem/150% var(--Baloo);
      letter-spacing: 0.096rem;
      text-decoration-line: line-through;
    }
  }
  &-time{
    color: var(--color-text-gray);
    font: 400 1.4rem/150% var(--Baloo);
  }
  &-label{
    position: absolute;
    width: 11.4rem;
    height: 3.5rem;
    top: 1.4rem;
    right: -1rem;
  }
  &-btn{
    display: flex;
    padding: 1.6rem 3rem;
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
    background: var(--color-blue);
    color: var(--color-white);
    font: 400 1.6rem/150% var(--Baloo);
    cursor: pointer;
    margin-top: 2rem;
  }
  hr{
    border-top: .1rem solid var(--color-light-blue3);
    width: 100%;
    margin: 2rem 0;
  }
  &-advantages{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    li{
      color: var(--color-light-gray);
      font: 400 1.4rem/150% var(--Baloo);
      position: relative;
      &:after{
          content: '';
          background: url("../img/check.svg");
          position: absolute;
          width: 1rem;
          height: 1rem;
          top: .5rem;
          left: -2rem;
          background-position: center;
          background-size: cover;
      }
      span{
        color: var(--color-text-gray);
        font-weight: 600;
      }
    }
  }
  .policy{
    color: var(--color-light-gray);
    font: 400 1.4rem/150% var(--SegoeUI);
    text-align: center;
    margin-top: 3rem;
    span{
      text-decoration-line: underline;
    }
  }
  h2{
    color: var(--color-text-gray);
    font: 600 2.4rem/150% var(--Baloo);
    text-align: center;
    margin: 7rem 0 5rem;
  }
  .plan-include{
    display: flex;
    gap: 3rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 7rem;
    li{
      display: flex;
      max-width: 37rem;
      width: 40vw;
      padding: 3rem 2rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      border-radius: 3rem;
      background: var(--color-white);
      box-shadow: 0 1rem 2.4rem 0 rgba(45, 152, 218, 0.14);
      img{
        width: 3rem;
        height: 3rem;
      }
      p{
        color:var(--color-light-gray);
        font: 400 1.6rem/150% var(--SegoeUI);
        text-align: center;
      }
    }
  }
  .latest-blog-btns{
    display: none;
  }
  .account-plan-swiper{
    flex-direction: column;
  }

}
@media (min-width: 666px) {
  .mobile{
    display: none;
  }
}
@media (max-width: 666px) {
  .mobile{
    display: unset;
  }
  .desc{
    display: none;
  }
  .account-plan-label{
    width: unset;
    height: unset;
    img{
      object-fit: contain;
    }
  }
  .account-plan-hidden {
    width: 100%;
  }
  .account-plan-swiper{
    flex-direction: row;
  }
  .account-plan-hidden > li > ul > li{
    width: 22rem;
    max-width: unset;
    padding: 2rem;
  }
  .account-plan-hidden > li > ul{
    gap: unset;
    padding:0 5rem;
  }
  .account-plan .plan-include{
    flex-direction: column;
    margin: 0 .6rem 5rem;
    gap: 1rem;
  }
  .account-plan-wrapper{
    width: 100%;
    overflow: hidden;
    margin-top: 1rem;
  }
  .account-plan-swiper{
    padding-right: 8rem;
    padding-bottom: 6rem;
  }
  .account-plan .latest-blog-btns{
    display: flex;
  }
  .account-plan .policy{
    margin-top: 2rem;
    width: 100vw;
    font-size: 1.3rem;
  }
  .account-plan .latest-blog-swiper-button-prev, .account-plan .latest-blog-swiper-button-next{
    margin: 0 2.6rem;
    background: var(--color-light-blue);
  }
  .account-plan-name{
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
  .account-plan-wrapper p{
    font-size: 1.3rem;
  }
  .account-plan-wrapper > p{
     margin: -0.6rem 0 -2rem;
  }
  .account-plan-price p{
    font-size: 3.6rem;
  }
  .account-plan-price span{
    font-size: 1.4rem;
  }
  .account-plan-btn{
    padding: 1rem 3rem;
  }
  .account-plan-advantages li{
    font-size: 1.3rem;
    span{
      font-weight: 500;
    }
  }
  .account-plan-advantages{
    padding-left: 2rem;
  }
  .account-plan .swiper-slide{
    height: auto;
  }
  .account-plan hr{
    margin: 1rem 0;
  }
  .account-plan-btn{
    margin-top: 1rem;
  }
  .account-plan-price .sales-price{
    font-size: 1.3rem;
    top: -1.3rem;
  }
  .account-plan-tab li{
    padding: .6rem 2rem;
  }
  .account-plan .error-massage{
    padding: 1.6rem 1rem;
    margin: 0 .6rem;
    width: 96%;
  }
  .account-plan .error-massage p{
    font-size: 1.3rem;
  }
  .account-plan h1{
    margin: 1.6rem 0 1rem;
    font-size: 2rem ;
  }
  .account-plan h2{
    margin: 5rem 0 2rem;
  }
  .account-plan .plan-include li{
    width: unset;
    padding: 2rem;
  }
  .account-plan .plan-include li p{
    font-size: 1.4rem;
  }
}
.select2-results__option:first-child{
  display: none;
}

.error-page{
   .mobile{
      display: none;
    }
  h1{
    color: var(--color-black);
    text-align: center;
    font: 600 3rem/150% var(--Baloo);
    position: absolute;
    top: 10rem;
    left: 50%;
    transform: translate(-50%);
  }
  a{
    width: max-content;
    position: absolute;
    border-radius: 1.6rem;
    padding: 1.4rem 2.4rem;
    top: 17rem;
    left: 50%;
    transform: translate(-50%);
  }
  img{
    object-fit: contain;
  }
}
@media (max-width: 666px) {
  .error-page .mobile{
    display: flex;
  }
  .error-page .desc{
    display: none;
  }
  .error-page h1{
    font-size: 2rem;
    width: 100%;
    padding: 0 1.6rem;
    top: 3rem;
  }
  .error-page a{
    font-size: 1.4rem;
    top: 10rem;
  }
  .error-page{
    height: 100vh;
    background-color: #ebf5fb;
  }

}
.privacy{
  display: flex;
  margin: 0 auto;
  max-width: 117rem;
  gap: 13rem;
  &-wrapper{
    max-width: 77rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 10rem;
  }
  .tabs-anker{
    display: flex;
    flex-direction: column;
    gap: .8rem;
    h2{
      color: var(--color-text-gray);
      font: 500 1.8rem/150% var(--Baloo);
      margin-bottom: .6rem;
    }
    a{
      color: var(--color-blue);
      font: 400 1.6rem/150% var(--Baloo);
      position: relative;
    }
    .active{
      padding-left: 3rem;
      font-weight: 500;
      &:after{
        content: '';
        position: absolute;
        top: 1rem;
        left: 0;
        width: 2rem;
        height: 0.1rem;
        background: var(--color-blue);
      }
    }
  }
  h1{
    color: var(--color-text-gray);
    font: 700 4rem/150% var(--Baloo);
    margin-top: 1.1rem;
  }
  h2{
    color: var(--color-text-gray);
    font: 600 2.4rem/150% var(--Baloo);
  }
  h3{
    color: var(--color-text-gray);
    font: 500 1.8rem/150% var(--Baloo);
  }
  h4{
    color: var(--color-text-gray);
    font: 600 1.4rem/150% var(--SegoeUI);
  }
  p{
    color: var(--color-text-gray);
    font: 500 1.8rem/150% var(--Baloo);
  }
  small{
    color: var(--color-text-gray);
    font: 400 1.6rem/150% var(--Baloo);
  }
  .gray{
    color: var(--color-light-gray);
  }
  .box{
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    p{
      font: 400 1.6rem/150% var(--Baloo);
      a{
        text-decoration: underline;
      }
    }
  }
  ul{
    li{
      color: var(--color-light-gray);
      font: 400 1.6rem/160% var(--SegoeUI);
      padding-left: 2rem;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        width: .6rem;
        height: .6rem;
        border-radius: 1rem;
        background: var(--color-blue);
        top: 1rem;
        left: 0;
      }
    }
  }
  a{
    color: var(--color-text-gray);
    font: 400 1.6rem/150% var(--Baloo);
    text-decoration-line: underline;
  }
  table{
    th{
      width: 50%;
      padding: 1rem;
      border: .1rem solid var(--color-light-gray2);
      background: var(--color-light-white);
      color: var(--color-blue);
      text-align: left;
      font: 500 1.8rem/150% var(--Baloo);
    }
    td{
      padding: 1rem;
      border: .1rem solid var(--color-light-gray2);
      vertical-align: top;
      &:first-child{
        p{
          color: var(--color-light-gray);
        }
      }
      a, p, h3{
        margin-bottom: 1rem;
      }
    }
  }
  #introduction{
    margin: 2rem 0 1rem;
  }
}
@media (max-width: 666px) {
  .privacy .tabs-anker{
    display: none;
  }
  .privacy .box table p a, .privacy table td:first-child p{
    width: 38vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
  .privacy-wrapper{
    margin: 1.6rem 1.6rem 7rem;
  }
  .privacy h1{
    font-size: 2rem;
    text-align: center;
  }
  .privacy h2{
    font-size: 2rem;
  }
  .privacy h3{
    font-size: 1.6rem;
  }
  .privacy h4{
    font-size: 1.3rem;
  }
  .privacy p{
    font-size: 1.6rem;
  }
  .privacy small, .privacy .box p, .privacy a, .privacy ul li{
    font-size: 1.4rem;
  }
  .privacy table th{
    font-size: 1.6rem;
  }
  .privacy ul li:after{
    top: .8rem;
  }
}
